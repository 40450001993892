import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
   <svg xmlns="http://www.w3.org/2000/svg"   width="100%" height="100%" viewBox="0 0 1080.000000 653.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g 
fill={PRIMARY_MAIN}  stroke="none" strokeWidth="1"  fillRule="evenodd" transform="translate(0.000000,653.000000) scale(0.100000,-0.100000)">

<path d="M3233 4170 c-75 -18 -132 -73 -153 -149 -6 -23 -10 -333 -10 -842 0
-697 2 -808 15 -819 8 -6 19 -10 24 -8 6 1 63 73 128 158 65 86 128 168 139
183 l22 27 2091 0 2092 0 -58 78 c-31 42 -133 182 -227 311 -138 190 -171 231
-180 218 -6 -8 -40 -56 -75 -106 l-63 -91 -79 0 c-44 0 -79 3 -79 7 0 4 8 16
18 27 10 12 60 79 112 149 82 114 91 131 81 150 -6 12 -65 94 -131 182 -66 88
-179 240 -252 338 -72 98 -139 182 -149 187 -21 12 -3218 12 -3266 0z m635
-420 c57 -26 77 -43 114 -99 40 -64 38 -86 -11 -99 -38 -9 -40 -8 -46 16 -18
74 -90 122 -181 122 -132 0 -223 -98 -223 -240 0 -149 91 -244 234 -245 60 0
76 4 113 28 46 30 79 75 89 120 5 23 10 28 27 23 12 -3 31 -6 44 -6 29 0 28
-21 -2 -85 -24 -53 -82 -112 -138 -141 -48 -25 -171 -30 -241 -10 -73 21 -156
95 -186 167 -81 188 14 414 196 465 61 17 151 10 211 -16z m672 -1 c66 -28
142 -111 167 -181 27 -80 24 -189 -8 -258 -34 -73 -76 -120 -141 -156 -49 -27
-61 -29 -153 -29 -114 0 -156 16 -225 85 -122 122 -126 331 -9 464 88 100 239
131 369 75z m653 -22 c21 -15 43 -40 51 -55 47 -94 15 -217 -68 -256 l-35 -16
34 -74 c18 -40 45 -96 59 -124 14 -28 26 -56 26 -62 0 -5 -20 -10 -44 -10
l-43 0 -61 130 -62 130 -85 0 -85 0 0 -130 0 -130 -45 0 -45 0 0 316 0 316
183 -4 c183 -3 184 -3 220 -31z m490 18 c69 -29 114 -112 103 -191 -8 -63 -30
-100 -78 -131 -38 -25 -49 -28 -169 -31 l-129 -4 0 -129 0 -129 -45 0 -45 0 0
315 0 315 164 0 c121 0 173 -4 199 -15z m400 -228 c56 -131 103 -234 105 -230
1 5 46 112 100 238 l98 230 77 3 77 3 0 -316 0 -315 -60 0 -60 0 -2 193 -3
192 -81 -190 -82 -190 -71 -3 c-43 -2 -71 1 -71 7 0 6 -35 92 -77 192 l-78
181 -3 -191 -2 -191 -65 0 -65 0 0 315 0 316 81 -3 81 -3 101 -238z m647 -72
l0 -315 -65 0 -65 0 0 315 0 315 65 0 65 0 0 -315z"/>
<path d="M4303 3665 c-119 -60 -163 -208 -100 -337 108 -221 437 -134 437 116
0 185 -177 300 -337 221z"/>
<path d="M4880 3580 c0 -73 3 -100 13 -100 27 -1 164 -2 195 -1 72 2 113 82
77 150 -20 40 -61 51 -181 51 l-104 0 0 -100z"/>
<path d="M5410 3578 l0 -103 121 5 c117 5 122 6 145 33 15 18 24 41 24 63 0
82 -39 104 -185 104 l-105 0 0 -102z"/>
</g>
      </svg>
    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
